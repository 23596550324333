<template>
  <b-container>
    <div id="chart" class="my-3">
      <apexchart v-if="this.$store.state.reports.length!==0" type="bar"  :height="height" :options="chartOptions" :series="[{ name: label, data: chartData }]"></apexchart>
    </div>
  </b-container>
</template>

<script>
export default {
  props: ["height","label","color"],
  computed: {
    sortedReports(){
      return this.$store.state.reports.sort((a, b) => (a.downloaded > b.downloaded) ? -1 : 1)
    },
    chartLabels() {
      return Object.keys(this.sortedReports).map((item) => {
        return this.sortedReports[item].slug;
      });
    },
    chartData() {
      return Object.keys(this.sortedReports).map((item) => {
        return this.sortedReports[item].downloaded;
      });
    },
    chartOptions() {
      return {
        chart: {
          height: this.height,
          type: "bar",
          toolbar: {
            show: false
          }
        },
        colors:[this.$props.color],
        plotOptions: {
          bar: {
            horizontal: true,

            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: this.chartLabels,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          title:{
            text:this.$props.label,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
          },
        },
        title: {
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      };
    },
  },
  data() {
    return {};
  },
};
</script>
